import React, { useRef } from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../components/SEO/seo';
import FlowLogo from '../assets/images/flow-logo-alt.svg';
import banner from '../assets/images/flow-plus-cover.png';
import Social from '../components/Social';
import Dialog from '../components/Dialog';
import YouTube from 'react-youtube';

type ElementNumber = 1 | 2 | 3 | 4;

const FlowPlus = (page: PageProps) => {
  const ref1 = useRef<null | HTMLElement>(null);
  const ref2 = useRef<null | HTMLElement>(null);
  const ref3 = useRef<null | HTMLElement>(null);
  const ref4 = useRef<null | HTMLElement>(null);

  const executeScroll = (num: ElementNumber) => {
    const refObject = (num: ElementNumber) => {
      if (num === 1) return ref1;
      if (num === 2) return ref2;
      if (num === 3) return ref3;
      return ref4;
    };
    refObject(num).current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };
  return (
    <>
      <Seo page={page} banner={banner} title="Flow Plus" />
      <main className="page-flow-plus">
        <section className="section-1">
          <div className="container masterclass-container">
            <div className="section-1__content">
              <img src={FlowLogo} alt="flow" className="section-1__logo" />
              <h1 className="section-1__title u-text-primary">
                Hoe start je met Profit First en Flow
              </h1>
              <div className="section-1__subtitle">
                Hieronder vind je de instructie video's inclusief de Nederlandse
                versie van het Profit First audioboek. Heel veel plezier op weg
                naar permanente winst!
              </div>
              <div className="section-1__text u-color-lavender">
                <p>
                  De Profit First methode is eenvoudig. Telkens als je een
                  betaling krijgt, neem je een vooraf bepaald percentage van dat
                  geld als winst. Natuurlijk zijn er nog een paar stappen meer
                  dan alleen dat. Maar zelfs met de eenvoudige eerste stap, om
                  eerst je winst te nemen, zul je blijvend winstgevend worden.
                </p>
                <p>
                  In deze korte video's vertelt de schrijver van Profit First,
                  Mike Michalowicz waarom deze geld methode zo goed werkt.
                  Daarna vertelt Femke Hogema over de 4 principes van Profit
                  First en hoe je het in de parktijk goed inricht. Tot slot laat
                  Daan van Klinken stap voor stap zien hoe je de Profit First
                  Flows in de app kunt instellen. En speciaal voor jou kun je
                  onderaan deze pagina ook het Profit First luisterboek vinden,
                  vertaald door Femke.
                </p>
                <p>
                  Winst is geen gebeurtenis. Winst is een gewoonte. Deze cursus
                  laat je precies zien hoe je dat kunt doen! Veel plezier!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-2 u-section-padding-sm">
          <div className="container masterclass-container">
            <div className="section-2__content">
              <h2 className="section-2__title">Inhoud</h2>
              <ul className="section-2__list">
                <a className="section-2__item" onClick={() => executeScroll(1)}>
                  Introductie door Mike Michalowicz
                </a>
                <a className="section-2__item" onClick={() => executeScroll(2)}>
                  Starten met Profit First door Femke Hogema
                </a>
                <a className="section-2__item" onClick={() => executeScroll(3)}>
                  Stap voor stap je Flows instellen met Daan
                </a>
                <a className="section-2__item" onClick={() => executeScroll(4)}>
                  Luisterboek Profit First
                </a>
              </ul>
            </div>
          </div>
        </section>
        <section className="section-3 u-section-padding-sm" ref={ref1}>
          <div className="container masterclass-container">
            <div className="section-3__content">
              <h2 className="section-3__title u-text-primary">Introductie</h2>
              <div className="section-3__text u-color-lavender">
                In deze korte maak je kennis met de schrijver van Profit First,
                Mike Michalowicz. Hij legt uit waarom geld methode zo goed werkt
                voor bedrijven en hoe Flow daarbij een goed hulpmiddel is.
              </div>
              <YouTube videoId="m9qUFeQ4hAo" className="section-2__iframe" />
            </div>
          </div>
        </section>
        <section className="section-4 u-section-padding-sm" ref={ref2}>
          <div className="container masterclass-container">
            <div className="section-4__content">
              <h2 className="section-4__title u-text-primary">
                Starten met Profit First
              </h2>
              <div className="section-4__block">
                <h3 className="section-4__subtitle u-text-primary">
                  1: Wat is Profit First?
                </h3>
                <div className="section-4__text u-color-lavender">
                  Een jaar of 6 geleden las Femke een boek en zat op het puntje
                  van haar stoel. Ze dacht: Dit moeten alle ondernemers weten!
                  Femke vertelt wat er vervolgens gebeurde en hoe Profit First
                  ontstaan is.
                </div>
                <YouTube videoId="40D6T70cJOM" className="section-4__iframe" />
              </div>
              <div className="section-4__block">
                <h3 className="section-4__subtitle u-text-primary">
                  2: De 4 principes van Profit First
                </h3>
                <div className="section-4__text u-color-lavender">
                  Profit First werkt op basis van 4 eenvoudige principes.
                  Waarschijnlijk hele herkenbare principes maar fundamenteel om
                  financieel gezond te worden. Deze video gaat daar dieper op
                  in.
                </div>
                <YouTube videoId="1FiZ5OIiBwg" className="section-4__iframe" />
              </div>
              <div className="section-4__block">
                <h3 className="section-4__subtitle u-text-primary">
                  3: Profit First in de praktijk
                </h3>
                <div className="section-4__text u-color-lavender">
                  Hoe ga je nou daadwerkelijk aan de slag met Profit First? Van
                  je bank tot aan het bepalen van je persoonlijke percentages om
                  je geld te verdelen.
                </div>
                <YouTube videoId="gfqFl7qt5Cg" className="section-4__iframe" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-5 u-section-padding-sm" ref={ref3}>
          <div className="container masterclass-container">
            <div className="section-5__content">
              <h2 className="section-5__title u-text-primary">
                Profit First automatiseren met Flow
              </h2>
              <div className="section-5__block">
                <h3 className="section-5__subtitle u-text-primary">
                  Introductie
                </h3>
                <div className="section-5__text u-color-lavender">
                  Hoe automatiseer je je Profit First, en wat heb je daar voor
                  nodig? In deze video introduceer ik een 3-stappen systeem
                  waarmee jij jouw Profit First kunt automatiseren.
                </div>
                <YouTube videoId="H9gwp5ii-IQ" className="section-5__iframe" />
              </div>
              <div className="section-5__block">
                <h3 className="section-5__subtitle u-text-primary">
                  Potjes koppelen en Flows maken
                </h3>
                <div className="section-5__text u-color-lavender">
                  De basis van het automatiseren van Profit First is het
                  aanmaken van potjes (bankrekeningen) en het koppelen van de
                  potjes aan de Flow app. In deze video laat ik je zien hoe dit
                  werkt en wat er dan gebeurt als er geld binnenkomt.
                </div>
                <YouTube videoId="n6Cjgmp02Ig" className="section-5__iframe" />
              </div>
              <div className="section-5__block">
                <h3 className="section-5__subtitle u-text-primary">
                  De standaard Profit First flows instellen
                </h3>
                <div className="section-5__text u-color-lavender">
                  Stap 1 van het Profit First systeem is: het verdelen van je
                  inkomsten over de Profit First potjes. Dit gebeurt standaard
                  elke 10e en 25e van de maand. Ik laat je zien hoe je dit in
                  kunt stellen in de app.
                </div>
                <YouTube videoId="8hfftR0L23s" className="section-5__iframe" />
              </div>
              <div className="section-5__block">
                <h3 className="section-5__subtitle u-text-primary">
                  Hoe wil je je salaris uitbetaald krijgen?
                </h3>
                <div className="section-5__text u-color-lavender">
                  Wil je je salaris elke maand, week, elke twee weken? Of
                  dynamisch uitbetaald krijgen? Dat is de tweede stap van het
                  systeem, stel in hoe je jezelf uit wil betalen vanuit je
                  "Salaris" potje die je in de vorige stap hebt gevuld.
                </div>
                <YouTube videoId="0oNhUi07i5w" className="section-5__iframe" />
              </div>
              <div className="section-5__block">
                <h3 className="section-5__subtitle u-text-primary">
                  De laatste stap: De kwartaalafronding
                </h3>
                <div className="section-5__text u-color-lavender">
                  Wat doe je eigenlijk met die winst die je apart gezet hebt? In
                  deze video vind je het antwoord. Elk kwartaal ga je de winst
                  verdelen en de inkomstenbelasting apart zetten. Hoe je dat
                  automatiseert, laat ik je zien in deze video:
                </div>
                <YouTube videoId="rJHViLfQQdE" className="section-5__iframe" />
              </div>
            </div>
          </div>
        </section>
        <section className="section-6 u-section-padding-sm" ref={ref4}>
          <div className="container masterclass-container">
            <div className="section-6__content">
              <h2 className="section-6__title u-text-primary">
                Luisterboek Profit First
              </h2>
              <div className="section-6__text u-color-lavender">
                Speciaal voor onze klanten hebben wij een Nederlandse versie van
                het Profit First luisterboek beschikbaar. Je kunt hem hieronder
                luisteren of direct downloaden via de volgende link:
              </div>
              <iframe
                width="100%"
                height="300"
                scrolling="no"
                allow="autoplay"
                className="section-6__iframe"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1139825263%3Fsecret_token%3Ds-77NzRNwox08&color=%23ef357d&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              ></iframe>
            </div>
          </div>
        </section>
        <footer className="masterclass-footer">
          <div className="container masterclass-container">
            <div className="masterclass-footer__content">
              <div className="masterclass-footer__copyright">
                Copyright 2021 Flow Money Automation B.V.
              </div>
              <Social className="masterclass-footer__social" extended />
            </div>
          </div>
        </footer>
        <Dialog />
      </main>
    </>
  );
};

export default FlowPlus;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

// # Stap voor stap je Flows instellen

// ###

// WIP by Daan

// ### Kiezen van Templates

// WIP by Daan

// ### Stel je templates in

// WIP by Daan

// ### Profit First in Flow in werking

// WIP by Daan

// ### Op weg naar stabiele winst!

// WIP by Daan
